import pro1 from "../pic/project1.png";
import pro2 from "../pic/project2.png";
import pro3 from "../pic/project3.png";
import pro4 from "../pic/project4.png";
const ProjectCard =[
    {
        imgsrc: pro1,
        title:"City Gym Website",
        text:"It Is A Web-Page Layout About Gym ",
        view:"https://citygym.pages.dev/"
    },
    {
        imgsrc: pro2,
        title:"To-Do List",
        text:"It Is A To-do List for making notes ",
        view:"https://todo-2cx.pages.dev/"
    },
    {
        imgsrc: pro3,
        title:"imdb clone app",
        text:"It Is A Clone Of Imdb ",
        view:"https://imdb-f1l.pages.dev/"
    },
    {
        imgsrc: pro4,
        title:"Portfolio",
        text:"It Is About My Portfolio ",
        view:"https://imdb-f1l.pages.dev/"
    }
];
export default ProjectCard;
